import { all, call } from 'redux-saga/effects'

import paymentsSaga from './sagas/payments'
import feedbacksSaga from './sagas/feedbacks'

function* rootSaga() {
  yield all([
    call(paymentsSaga),
    call(feedbacksSaga),
  ])
}

export default rootSaga
