import React, { useContext } from 'react'
import { UIVersion } from '../../UIVersionContext'
import MobileVersion from './Mobile'
import DescktopVersion from './Descktop'

const LandingPage = () => {
  const uiVersion = useContext(UIVersion);

  if (uiVersion === 'mobile') {
    return (
      <div className="wrap mobile">
        <MobileVersion />
      </div>
    )
  }

  return <DescktopVersion />
}

export default LandingPage
