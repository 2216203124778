export const defaultSelectOption = { id: '', label: '- unset -' }
export const sizeOptionsLabel = { id: '', label: 'dimension de benne ' }

export const paidStatusOptions = [
  defaultSelectOption,
  {
    label: 'Paye',
    id: '1',
  },
  {
    label: 'Avec prix (impayé)',
    id: '2',
  },
  {
    label: 'Avec prix',
    id: '3',
  },
  {
    label: 'Pas de prix',
    id: '4',
  },
  {
    label: 'Avec TTC',
    id: '5',
  },
  {
    label: 'Pas de TTC (cash)',
    id: '6',
  },
  {
    label: 'Travail suppl.',
    id: '7',
  },
]

export const debtsStatusOptions = [
  defaultSelectOption,
  {
    id: '1',
    label: 'Paye',
  },
  {
    id: '2',
    label: 'Impayé',
  },
]

export const sizeOptions = [
  {
    id: '1',
    label: '8m³',
    coeficient: '350',
  },
  {
    id: '2',
    label: '10m³',
    coeficient: '400',
  },
  {
    id: '4',
    label: '15m³',
    coeficient: '550',
  },
]

export const statusOptionsList = [
  defaultSelectOption,
  {
    id: '3',
    label: 'Pansante',
  },
  {
    id: '2',
    label: 'Dépôt',
  },
  {
    id: '1',
    label: 'Echangé',
  },
  {
    id: '0',
    label: 'Récupéré',
  },
  {
    id: '4',
    label: 'P.A.V',
  },
]

export const trashTypes = [
  {
    id: '1',
    label: 'Gravats',
    coeficient: {
      1: '340',
      2: '390',
    },
  },
  {
    id: '2',
    label: 'Dechets',
    coeficient: {
      1: '410',
      2: '455',
      4: '615',
    },
  },
  {
    id: '5',
    label: 'Bois',
    coeficient: {
      1: '400',
      2: '445',
      4: '585',
    },
  },
  {
    id: '6',
    label: 'Vegetaux',
    coeficient: {
      1: '430',
      2: '480',
      4: '630',
    },
  },
]

export const sizeOptionsList = [defaultSelectOption, ...sizeOptions]
export const trashTypeOptionsList = [defaultSelectOption, ...trashTypes]

export const trashTypesSize = {
  1: [sizeOptions[0], sizeOptions[1]],
  2: sizeOptions,
  3: sizeOptions,
  4: sizeOptions,
  5: sizeOptions,
  6: sizeOptions,
}

export const orderCountLabels = [
  {
    id: 1,
    label: '1',
  },
  {
    id: 2,
    label: '2',
  },
  {
    id: 3,
    label: '3',
  },
]

export const depoCoords = {
  lat: 48.9980835,
  lng: 2.4770252,
}
