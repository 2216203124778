import React, { useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { setHours, setMinutes } from 'date-fns'
import { PlacesAutocomplete } from '../../../common/components'
import { trashTypes, trashTypesSize } from '../../../common/constants'
import { useForm } from '../../../common/hooks'
import { getDistanceToClient } from './utils'

import PerformPreorder from './PerformPreorder'

const tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)
tomorrow.setHours(9)
tomorrow.setMinutes(0)

const isNotWeekend = date => new Date(date).getDay() !== 0

const defaultFormData = {
  since: tomorrow,
  trash_type: trashTypes[0].id,
  benne_size: trashTypes[0].id,
}

const OrderForm = () => {
  const [invalidInvoice, setIsInvalidInvoice] = useState()
  const [invoiceParams, setInvoiceParams] = useState()
  const inputRef = useRef()

  const {
    handleChange,
    handleResetForm,
    values: formData,
    customHandleChange,
  } = useForm(defaultFormData)

  const handleSubmitForm = e => {
    e.preventDefault()

    setInvoiceParams(formData)
  }

  const resetInvoice = () => {
    setInvoiceParams(null)

    setTimeout(() => {
      handleResetForm(inputRef.current)
    }, 50)
  }

  const onKeyPress = e => {
    const keyCode = e.keyCode || e.which
    if (keyCode === 13) {
      e.preventDefault()

      return false
    }
  }

  if (invoiceParams) {
    return (
      <PerformPreorder
        invoiceParams={invoiceParams}
        onResetInvoice={resetInvoice}
      />
    )
  }

  return (
    <form
      onKeyPress={onKeyPress}
      onSubmit={handleSubmitForm}
      className="inputForm home_order_form"
      ref={inputRef}
    >
      <div className="formHeader fullWidth">
        <h1>Passer commande</h1>
        <span>
          Pour calculer le prix recommandé et les options de livraison de notre service, complete les details pour votre commande
        </span>
      </div>
      <PlacesAutocomplete
        className="order_form_input container fullWidth"
        placeholder="entrez l'adresse de votre chantier"
        onChange={value => {
          getDistanceToClient(value, result => {
            if (result.distance < 40) {
              setIsInvalidInvoice(false)
              customHandleChange('location', value)
              customHandleChange('distanceParams', result)
              customHandleChange('location_label', value.location)
            } else {
              setIsInvalidInvoice(true)
            }
          })
        }}
        restrictions={{
          componentRestrictions: {
            country: 'fr',
          },
          types: ['address'],
        }}
        value={formData.location_label}
      />

      <div className="formFooter">
        {invalidInvoice && (
          <span className="submitStatus error">
            votre emplacement est hors de notre zone de service
          </span>
        )}
      </div>
      <select
        className="order_form_input container fullWidth"
        value={formData.trash_type}
        onChange={element => {
          customHandleChange('trash_type', element.target.value)
          customHandleChange(
            'benne_size',
            trashTypesSize[element.target.value][0].id,
          )
        }}
        name="trash_type"
      >
        {trashTypes.map(trashType => (
          <option key={trashType.id} value={trashType.id}>
            {trashType.label}
          </option>
        ))}
      </select>

      <select
        className="order_form_input container fullWidth"
        value={formData.benne_size}
        onChange={handleChange}
        name="benne_size"
      >
        {trashTypesSize[formData.trash_type].map(bennesize => (
          <option key={bennesize.id} value={bennesize.id}>
            {bennesize.label}
          </option>
        ))}
      </select>

      <div className="order_form_input calendar container fullWidth">
        <DatePicker
          selected={formData.since}
          minDate={tomorrow}
          onChange={value =>
            customHandleChange('since', new Date(value))
          }
          filterDate={isNotWeekend}
          locale="fr"
          showTimeSelect
          timeIntervals={60}
          timeCaption="l'heure"
          timeFormat="HH:mm"
          minTime={setHours(setMinutes(tomorrow, 0), 8)}
          maxTime={setHours(setMinutes(tomorrow, 0), 17)}
          dateFormat="MMMM d, yyyy HH:mm"
          className="calendarInput"
          placeholderText="entrez la date de livraision"
        />
      </div>

      <div className="formFooter">
        <button
          type="submit"
          className="button"
          disabled={invalidInvoice || !formData.location}
        >
          voir l'offre
        </button>

        <p className="submitOrderNotes">
          Service fourni en région parisienne, départements: 75, 92, 93, 94, 95 (partiellement desservies: 60, 77, 78).
        </p>
      </div>
    </form>
  )
}

export default OrderForm
