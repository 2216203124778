import React, { useState, Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { propEq } from 'ramda'
import { sizeOptions, trashTypes } from '../../../common/constants'
import { submitPreorder } from '../../../actions/payments'
import { useForm } from '../../../common/hooks'

import getUnixTime from 'date-fns/getUnixTime'

const PerformPayment = ({ onResetInvoice, invoiceParams }) => {
  const { values: formData, handleChange: setFormData } = useForm()
  const [paymentStatus, setPaymentStatus] = useState('')

  const benneSize = sizeOptions.find(propEq('id', invoiceParams.benne_size))
  const trashType = trashTypes.find(propEq('id', invoiceParams.trash_type))
  const requestedDeliverTime = format(invoiceParams.since, 'dd MMM HH:mm')

  const dispatch = useDispatch()

  const submitPaymentCallback = response => {
    setPaymentStatus(response.status === 200 ? 'success' : 'error')
  }

  const onSubbmitPayment = () => {
    setPaymentStatus('pending')
    dispatch(
      submitPreorder({
        formData: {
          amount: trashType.coeficient[benneSize.id],
          client: formData,
          invoiceParams: {
            ...invoiceParams,
            distance: invoiceParams.distanceParams.distance,
            since: getUnixTime(invoiceParams.since),
            trash_type: trashType.id,
            status: 2,
          },
        },
        submitCallback: submitPaymentCallback,
      }),
    )

    setPaymentStatus('')
  }

  return (
    <div className="inputForm home_order_form">
      <div className="formHeader container fullWidth">
        <h3>Votre résultat</h3>
      </div>

      <div className="progressStep container fullWidth">
        <div className="tick-icon container" />
        <p className="container box-34 description">
          {`service offert: benne pour ${trashType.label} de ${benneSize.label}`}
        </p>
      </div>

      <div className="progressStep container fullWidth">
        <div className="tick-icon container" />
        <p className="container box-34 description">
          {`temps de livration approximative: ${requestedDeliverTime}`}
        </p>
      </div>

      <div className="progressStep container fullWidth">
        <div className="tick-icon container" />
        <p className="container box-34 description">
          {`destination: ${invoiceParams.location.location}`}
        </p>
      </div>

      <div className="progressStep container fullWidth totalsFooter">
        service prix: <strong>{trashType.coeficient[benneSize.id]}€ HT</strong>
      </div>

      <div className="formHeader container fullWidth">
        <h3>Vos coordonnées</h3>
      </div>

      <input
        required
        name="name"
        type="string"
        value={formData.name || ''}
        onChange={setFormData}
        placeholder="votre nom"
        className="order_form_input container fullWidth"
        disabled={paymentStatus === 'success' || paymentStatus === 'pending'}
      />

      <input
        name="phone"
        type="tel"
        value={formData.phone || ''}
        onChange={setFormData}
        placeholder="votre téléphone"
        className="order_form_input container fullWidth"
        disabled={paymentStatus === 'success' || paymentStatus === 'pending'}
      />

      <input
        name="email"
        type="mail"
        value={formData.email || ''}
        onChange={setFormData}
        placeholder="votre email"
        className="order_form_input container fullWidth"
        disabled={paymentStatus === 'success' || paymentStatus === 'pending'}
      />

      <div className="formFooter">
        {(paymentStatus === 'success' && (
          <Fragment>
            <p className="submitOrderNotes">
              Merci pour la commande. Nous avons reçu votre message et nous
              prendrons contact avec vous sous peu.
            </p>
            <button className="button" onClick={onResetInvoice}>
              finis
            </button>
          </Fragment>
        )) || (
          <Fragment>
            {paymentStatus === 'error' && (
              <span className="submitStatus error">
                les données du formulaire ne sont pas valides!
              </span>
            )}
            <button
              className="button"
              onClick={onSubbmitPayment}
              disabled={paymentStatus === 'pending'}
            >
              {(paymentStatus === 'pending' && 'en traitement...') ||
                'passer la commande'}
            </button>
            <button
              className="button cancel"
              onClick={onResetInvoice}
              disabled={paymentStatus === 'pending'}
            >
              anuler
            </button>
          </Fragment>
        )}
      </div>
      <div className="formFooter">
        <p className="submitOrderNotes">
          En passant cette commande, vous acceptez notre système{' '}
          <Link to="terms" className="link" target="_blank">
            conditions d'utilisation.
          </Link>
        </p>
      </div>
    </div>
  )
}

export default PerformPayment
