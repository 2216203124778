import React from 'react'
import { Layout } from '../../common/components'
import './style.scss'

export { default as CompanyReview } from './CompanyReview'
export { default as PrivacyPolicy } from './PrivacyPolicy'
export { default as CompanyTerms } from './CompanyTerms'
const TermsPage = () => {
  return (
    <Layout>
      <div className="legacyContent">
        <div className="content">
          <h1>Conditions générales d'utilisation (CGU)</h1>
          <h2 id="definitions">Définitions</h2>
          <article>
            <h3>
              Les définitions suivantes seront utilisées dans le présent accord:
            </h3>
            <p>
              <strong>CGU</strong> - désigne les présentes conditions générales 
              d’utilisation du Site Internet <strong>www.ama-loc.fr</strong> ou tout autre site internet exploité par la Société ;
            </p>
            <p>
              <strong>Commande</strong> - désigne les services d’enlèvement et
              de planification de l’enlèvement de déchets inertes et non
              dangereux sur les chantiers proposés par la société;
            </p>
            <p>
              <strong>Site</strong> - désigne{' '}
              <strong>www.ama-loc.fr</strong> ou tout autre site internet
              exploité par la Société et mis à disposition dans le cadre des
              services ;
            </p>
            <p>
              <strong>Client</strong> - désigne tout utilisateur qui utilise les
              services de la société (et qui a donc déjà passé une commande
              acceptée par la société) ;
            </p>
            <p>
              <strong>Utilisateur</strong> - désigne toute personne physique ou morale ayant 
              créé un Compte Utilisateur sur le Site Internet, ou par téléphone auprès de la Société ;
            </p>
            <p>
              <strong>Société</strong> - désigne <strong>AMA</strong>, Société à
              responsabilité limitée au capital de 20 000 €, enregistrée au RCS
              de Bobigny sous le numéro 752 497 354, dont le siège social est
              sis 2-4 avenue Flore, 95500 Le Thillay ;
            </p>

            <p>
              <strong>​Tarif</strong> - désigne le prix applicable aux services
              qui est expressément communiqué au client via le site internet ou
              par téléphone avant validation de sa commande ;
            </p>
          </article>

          <h2 id="cgu_application">Champ d’application des CGU</h2>
          <article>
            <p>
              Les CGU régissent l’utilisation du site internet. En utilisant le site
              internet, tout utilisateur accepte les CGU et s’engage à les
              respecter. Le site internet permettent aux utilisateurs de
              solliciter les services de la société et donc de devenir clients
              de la société. Les CGU s'appliquent, sans restriction ni réserve à
              tous les utilisateurs téléchargeant ou navigant sur le site
              internet. Elles annulent et remplacent les conditions générales
              d’utilisation diffusées antérieurement. L’utilisateur est informé
              que la société pourra mettre à jour à tout moment les CGU. Chaque
              version des CGU entrera en vigueur à sa date de mise en ligne sur
              le site internet, et le restera jusqu’au prochain mise à jour de
              cette dernière. Tout Utilisateur reconnaît expressément que
              l’utilisation du Site Internet postérieurement à toute
              modification des CGU vaut acceptation de celles-ci.
            </p>
          </article>

          <h2 id="acces_au_site_internet">Accès au Site Internet</h2>
          <article>
            <p>
              Le site internet est accessible:
              <br />
              à toute personne physique disposant de la pleine capacité
              juridique pour s’engager au titre des CGU. La personne
              physique qui ne dispose pas de la pleine capacité juridique ne
              peut accéder à site internet et aux services qu’avec l’accord de
              son représentant légal.
              <br />
              à toute personne morale disposant de la pleine capacité juridique
              pour s’engager au titre des CGU, agissant par
              l’intermédiaire d’une personne physique disposant de la capacité
              juridique pour contracter au nom et pour le compte de ladite
              personne morale.
              <br />
              Le site internet est accessibles à tout moment, sauf interruption,
              programmée ou non, pour des besoins de maintenance, de problèmes
              de réseau ou en cas de force majeure. Aucun frais, ni
              dédommagement ne pourra être réclamé en cas d’interruption ou de
              suspension de l’accès au site internet, la société étant tenue
              uniquement à une obligation de moyens. Il est rappelé par ailleurs
              que la Société peut mettre fin ou modifier les caractéristiques du
              Site Internet à tout moment, et cela sans préavis. Il incombe à
              l’utilisateur d’obtenir l’accès au réseau de données nécessaire
              pour utiliser le site internet. Les frais relatifs à son réseau
              mobile, à sa connexion, et au matériel ou appareils utilisés par
              l’utilisateur pour accéder au site internet, ou procéder à leur
              mise à jour, sont à la charge exclusive de l’utilisateur
            </p>
          </article>

          <h2 id="commande_manage">
            Création, modification et suppression d’un Commande
          </h2>
          <article>
            <p>
              Le recours aux services, tant par le site internet ou par
              téléphone suppose d’avoir au préalable créé un Compte Utilisateur. 
              La création d’un Compte Utilisateur est gratuite, seule la passation 
              d’une commande en vue d’un Service étant payante,. La préventive de création
              d’une commande par téléphone est gratuite. 
              L’utilisateur veille à ce que les informations du Compte Utilisateur et demeurent exactes,
              complètes et à jour. L’utilisateur soumet un certain nombre
              d’informations afin de créer création d’une commande et notamment
              : nom, prénom, adresse e-mail, numéro de téléphone, ainsi que le
              nom de la société qu’il représente le cas échéant. Toute inscription incomplète ne sera pas validée.
              <br />
              L’utilisateur garantit que toutes les informations qu’il fournit à
              la création d’une commande sont exactes, à jour et sincères et ne
              sont entachées d’aucun caractère trompeur. Il déclare et garantit
              pour les personnes physiques, être majeur et en capacité de
              contracter, et pour les personnes morales, ne pas être en
              situation de procédure collective, et que la personne ayant
              procédé au passé d’une commande est habilitée à engager la
              personne morale. Il doit immédiatement contacter la société s’il
              remarque que son dates personales a été utilisé à son insu. Il
              reconnaît à la société le droit de prendre toutes mesures
              appropriées en pareil cas.
            </p>
          </article>

          <h2 id="passation_de_commande">
            Conditions de passation de commande
          </h2>
          <article>
            <h3>
              En cas de passation de la commande, l'Utilisateur peut payer les
              services de la manière suivante:
            </h3>
            <p>
              Le paiement des services est effectué par l'Utilisateur à l'avance
              à hauteur de 100% (cent pour cent) du coût des services choisis,
              par tout moyen choisi dans la rubrique «Mode de paiement» du site. 
              En outre, l'utilisateur paie un supplément de 50% du coût du service fourni à titre de garantie, 
              les fonds de ce montant peuvent être retirés pour rembourser les coûts supplémentaires au la societe.
              Garantie supplémentaire est restitué sur le compte du client dans les 30 jours suivant la prestation de service
              moins le coût de tous les frais supplémentaires.
            </p>
            <p>
              L'Utilisateur s'engage à payer les services fournis par le
              Prestataire.
            </p>
            <p>
              Payez en ligne sur le site avec votre carte bancaire VISA /
              MasterCard en utilisant le système de paiement du partenaire
              "Axepta BNP Paribas:". Le montant du retrait correspond au coût des
              marchandises et des services de livraison.
            </p>
            <p>
              En cas de refus de l'Utilisateur de services: en cas de refus
              unilatéral d'exécuter les prestations, l'Utilisateur est tenu au
              moins un jour avant l'exécution de la commande d'informer la SARL
              AMA à l'adresse e-mail service.amatransport@gmail.com de son refus ,
              présentation des documents, confirmation du paiement de la
              Commande et demande de remboursement.
            </p>
            <p>
              La SARL AMA se réserve le droit de refuser à l'Utilisateur de
              compléter la Commande avec le type de paiement "en espèces" ou
              avec le type "en ligne", si les services précédemment complétés de
              l'Utilisateur n'ont pas été exécutés par sa faute.
            </p>
            <p>
              En cas de modification de la commande, l'Utilisateur doit le
              notifier par e-mail ou par appel, au moins un jour à compter de la
              date d'exécution des services. En cas de non-respect de la règle
              donnée, le Fournisseur n'assume pas la responsabilité d'accomplir
              les modifications données. Si l'Utilisateur notifie le changement
              de commande moins d'un jour à l'avance, l'Utilisateur devra payer
              100% de la valeur des services demandés.
            </p>
          </article>

          <h2 id="utilisation_des_ervices">Utilisation des Services</h2>
          <article>
            <h3>Règlement pour location de bennes</h3>
            <p>
              Après de réceptionne une <strong>commande de benne</strong>, camion arriva à
              l'heure convenu avec client pour la pose et emplacement-remplacement
              ou enlèvement de benne, pour ça est très importante de reste sur que
              la place réserve est bien disponible et le passage accessible
              jusqu'au moment de manutention de benne bien passe. Le conseil est
              d'avoir un personé responsable présent pour intervenir et réagir sur
              client terrain, en case de enveniment que peut empêcher et bloquer la
              manutention de benne
            </p>
            <p>
              Pour <strong>bien pose la benne</strong> commande été obligatoire d'avoir une place
              réserve et vérifie l'accès en préalable, au de fourni le coordonne
              claire et légitime de placement la benne. Toute la responsabilité
              appartenant au client.
            </p>
            <p>
              Pour l'autorisation de <strong>réserver une place sur voie publique</strong>, 
              Il faudra remplir le formulaire CERFA n°
              14023*01 en avant, et le transmettre à la Mairie de client commune
              pour prendre une réponse.
            </p>
            <p>
              Pour beaucoup de situation est tré convenable de commander une <strong>benne passante </strong> 
              avec chargement sur place - 45mn ! En case que client passage est très serré ou bloque,
              pas de place resserve, ou client préférez de charger la benne sur
              place direct - commandes une benne passante qu'arrive sur votre
              adresse et client la chargez en 45 minutes - âpre que la benne est
              installée. Attentions la benne passante peu pas bloquer la
              circulation, ferme une voie et rester plus de 45 minutes.
            </p>
            <p>
              Toute l'échange de benne sont commande obligatoire en préalable.
            </p>
            <p>
              <strong>Le passage sur chantier</strong> sans aucunes opérations sont prisse en charge
              par clients. Dan le cas que client bien passé une commande
              et le camion arrivant dan le moment convenu, la benne est
              bloquée et non accessible, ou la commande est annulé pendant que
              le camion est sur la route ver adresse fourni - Société ne pouvons
              pas prendre aucune responsabilité et comme ça client êtes facture
              avec un montant 150 euro HT pour <strong>le passage</strong> en vide.
            </p>
            <p>
              Toute le <strong>surcharge de benne</strong> que posse en danger la transportations et
              circulations sur la route sont facture en supplémentaires avec 35€
              HT/m3
            </p>
          </article>

          <h2 id="comportaments_prohibes">Comportements prohibés</h2>
          <article>
            <p>
              Il est strictement interdit d’utiliser les Services aux fins
              suivantes:
              <br /> l’exercice d’activités illégales, frauduleuses ou portant
              atteinte aux droits ou à la sécurité des tiers
              <br />
              l’atteinte à l’ordre public ou la violation des lois et règlements
              en vigueur
              <br />
              l’intrusion dans le système informatique d’un tiers ou toute
              activité de nature à nuire, contrôler, interférer, ou intercepter
              tout ou partie du système informatique d’un tiers, en violer
              l’intégrité ou la sécurité
              <br />
              l’envoi d’emails non sollicités et/ou de prospection ou
              sollicitation commerciale
              <br />
              les manipulations destinées à améliorer le référencement d’un site
              tiers
              <br />
              l’aide ou l’incitation, sous quelque forme et de quelque manière
              que ce soit, à un ou plusieurs des actes et activités décrits
              ci-dessus
              <br />
              et plus généralement toute pratique détournant les Services à des
              fins autres que celles pour lesquelles ils ont été conçus.
              <br />
              <br />
              <br />
              Sont également strictement interdits:
              <br />
              tous comportements de nature à interrompre, suspendre, ralentir ou
              empêcher la continuité des Services
              <br />
              toutes intrusions ou tentatives d’intrusions dans les systèmes de
              la Société
              <br />
              tous détournements des ressources système de l’Site Internet
              <br />
              toutes actions de nature à imposer une charge disproportionnée sur
              les infrastructures de cette dernière
              <br />
              toutes atteintes aux mesures de sécurité
              <br />
              tous actes de nature à porter atteinte aux droits et intérêts
              financiers, commerciaux ou moraux de la Société, des Collecteurs
              ou des usagers de son application, et enfin plus généralement
              <br />
              tout manquement aux CGU ou à la loi
              <br />
              Il est strictement interdit de monnayer, vendre ou concéder tout
              ou partie de l’accès aux Services, ainsi qu’aux informations qui y
              sont hébergées et/ou partagées
            </p>
          </article>

          <h2 id="sanctions">Sanctions des manquements</h2>
          <article>
            <p>
              En cas de manquement à l’une quelconque des dispositions des CGU
              ou plus généralement, d’infraction aux lois et règlements en
              vigueur par un Utilisateur, la Société se réserve le droit de
              prendre toute mesure appropriée et notamment de :
              <br />
              suspendre, supprimer ou empêcher l’accès aux Services du Client,
              auteur du manquement ou de l’infraction, ou y ayant participé,
              avec effet immédiat, publier sur le Site Internet tout message
              d’information que la Société jugera utile, avertir toute autorité
              concernée, engager toute action judiciaire.
            </p>
          </article>

          <h2 id="responsabilite_de_la_societe">
            Responsabilité et garantie de la Société
          </h2>
          <article>
            <p>
              La Société s’engage à fournir les Services avec diligence et selon
              les règles de l’art, étant précisé qu’il pèse sur elle une
              obligation de moyens, à l’exclusion de toute obligation de
              résultat, ce que les Utilisateurs reconnaissent et acceptent
              expressément. La responsabilité de la Société ne peut être engagée
              qu’en cas de faute ou de négligence prouvée.
              <br />
              La Société décline toute responsabilité en cas de perte éventuelle
              des informations accessibles dans le Compte Utilisateur,
              l’Utilisateur devant en sauvegarder une copie et ne pouvant
              prétendre à aucun dédommagement à ce titre.
              <br />
              <br />
              <br />
              La Société ne garantit pas aux Clients :
              <br />
              que le Site Internet, soumis à une recherche constante pour en
              améliorer notamment la performance et le progrès, seront
              totalement exempts d’erreurs, de vices ou défauts
              <br />
              que le Site Internet, étant standards et nullement proposés à la
              seule intention d’un Client donné en fonction de ses propres
              contraintes personnelles, répondront spécifiquement à ses besoins
              et attentes
              <br />
              En tout état de cause, la responsabilité susceptible d’être
              encourue par la Société au titre des présentes est expressément
              limitée aux seuls dommages directs avérés subis par le Client ou
              l’Utilisateur.
              <br />
              Les données enregistrées dans le système informatique de la
              Société constituent la preuve de l’ensembles des échanges et
              transactions avec les Utilisateurs.
            </p>
          </article>

          <h2 id="propriete_intellectuelle">
            Droits de propriété intellectuelle
          </h2>
          <article>
            <p>
              Le Site Internet et tous droits de propriété intellectuelle qui
              leurs sont rattachés, à ce compris la marque « AMA » et les droits
              d’auteur sur le Site Internet, sont la propriété exclusive de la
              Société. Toutes les marques, logos et autres signes distinctifs,
              exploités sur le Site Internet sont protégés à ce titre par les
              lois, notamment par les dispositions du Code de la propriété
              intellectuelle, et par les traités applicables en France et à
              l’étranger.
              <br />
              L’Utilisateur s’engage à ne pas reproduire, pirater, télécharger,
              copier, mettre à disposition, diffuser ou utiliser d’une
              quelconque manière les textes, bases de données, données, codes,
              images ou le contenu de la marque, du Site Internet, et de manière
              générale à ne rien faire qui puisse contrevenir aux droits de
              propriété intellectuelle appartenant à la Société.
              <br />
              Toute utilisation non autorisée de la marque, du Site Internet (et
              notamment par procédé de piratage, contrefaçon ou téléchargement
              illicite) pourra donner lieu à des poursuites judiciaires civiles
              et/ou pénales et au paiement de dommages et intérêts à la Société.
            </p>
          </article>

          <h2 id="donnees_personnelles">Données personnelles</h2>
          <article>
            <p>
              L’Utilisateur est informé que son inscription sur le Site Internet
              donne lieu à la collecte et au traitement automatisé de données à
              caractère personnel le concernant par la Société. Il reconnait
              avoir pris connaissance de la ​ Politique de Confidentialité ​
              mise en place par la Société, comprise par renvoi dans les CGU.
            </p>
          </article>

          <h2 id="publicite">Publicité</h2>
          <article>
            <p>
              La Société se réserve la faculté d’insérer sur toute page du Site
              Internet et dans toute communication aux Clients tous messages
              publicitaires ou promotionnels sous une forme et dans des
              conditions dont la Société sera seule juge.
            </p>
          </article>

          <h2 id="liens_et_sites_tiers">Liens et sites tiers</h2>
          <article>
            <p>
              La Société ne pourra en aucun cas être tenue pour responsable de
              la disponibilité technique de sites internet exploités par des
              tiers (y compris ses éventuels partenaires) auxquels l’Utilisateur
              accéderait par l'intermédiaire du Site Internet. La Société
              n'endosse aucune responsabilité au titre des contenus, publicités,
              produits et/ou services disponibles sur de tels sites tiers dont
              il est rappelé qu’ils sont régis par leurs propres conditions
              d’utilisation. La Société n’encourt aucune responsabilité du fait
              des transactions intervenues entre un Utilisateur et un quelconque
              annonceur, professionnel ou commerçant (y compris ses éventuels
              partenaires) vers lequel l’Utilisateur serait orienté par
              l'intermédiaire du Site Internet, et ne saurait en aucun cas être
              partie à quelques litiges éventuels que ce soit avec ces tiers
              concernant notamment les services, les garanties, déclarations et
              autres obligations quelconques auxquelles ces tiers sont tenus.
            </p>
          </article>

          <h2 id="references_commerciales">Références commerciales</h2>
          <article>
            <p>
              Le Client autorise expressément la Société à le citer et à
              utiliser, le cas échéant, la reproduction de sa marque ou de son
              logo à titre de références commerciales, notamment lors de
              manifestations ou d’événements, dans ses documents commerciaux et
              sur son site internet, sous quelque forme que ce soit.
            </p>
          </article>

          <h2 id="loi_applicable_et_juridiction">
            Loi applicable et juridiction
          </h2>
          <article>
            <p>
              Les CGU sont régies par la loi française. En cas de contestation
              sur la validité, l’interprétation et/ou l’exécution des présentes
              conditions générales, les parties conviennent que les juridictions
              dans le ressort de la Cour d’appel de Paris seront exclusivement
              compétentes pour en juger, sauf règles de procédure impératives
              contraires. Les CGU sont rédigées en langue française. Dans le cas
              où elles seraient traduites en une ou plusieurs langues, seul le
              texte français ferait foi en cas de litige.
            </p>
          </article>

          <h2 id="acceptation">Acceptation</h2>
          <article>
            <p>
              Les CGU sont expressément agréées et acceptées par le Client, qui
              déclare et reconnaît en avoir une parfaite connaissance, et
              renonce, de ce fait, à se prévaloir de tout document
              contradictoire et, notamment, ses propres conditions générales
              d'achat, qui seront inopposables à la Société, même si la Société
              en a eu connaissance.
            </p>
          </article>

          <h2 id="contactes_nous">Contactes nous</h2>
          <article>
            <p>La Société peut être jointe aux coordonnées suivantes:</p>
            <p>
              (1) adresse: 2-4 Avenue FLORE 95500 Le Thillay, Paris, France
              <br />
              (2) téléphone:+33 6 17 55 20 52 <br />
              (3) mail: service.amatransport@gmail.com
              <br />
              <br />
              L’Utilisateur s’engage à privilégier toute voie amiable en cas de
              réclamation ou de litige faisant intervenir la Société.
              Réciproquement, la Société s’engage à répondre avec diligence à
              toute réclamation, et à privilégier toute voie amiable de
              résolution des litiges.
            </p>
          </article>

          <p>
            La présente version des CGU est entrée en vigueur le 1 novembre
            2020.
          </p>
        </div>

        <div className="navigation">
          <h4>Privacy</h4>

          <a href="#definitions">Définitions</a>
          <a href="#cgu_application">Champ d’application des CGU</a>
          <a href="#acces_au_site_internet">Accès au Site Internet</a>
          <a href="#commande_manage">
            Création, modification et suppression d’un Commande
          </a>
          <a href="#passation_de_commande">
            Conditions de passation de commande
          </a>
          <a href="#utilisation_des_ervices">Utilisation des Services</a>
          <a href="#comportaments_prohibes">Comportements prohibés</a>
          <a href="#sanctions">Sanctions des manquements</a>
          <a href="#responsabilite_de_la_societe">
            Responsabilité et garantie de la Société
          </a>
          <a href="#propriete_intellectuelle">
            Droits de propriété intellectuelle
          </a>
          <a href="#donnees_personnelles">Données personnelles</a>
          <a href="#publicite">Publicité</a>
          <a href="#liens_et_sites_tiers">Liens et sites tiers</a>
          <a href="#references_commerciales">Références commerciales</a>
          <a href="#loi_applicable_et_juridiction">
            Loi applicable et juridiction
          </a>
          <a href="#acceptation">Acceptation</a>
          <a href="#contactes_nous">Contactes nous</a>
        </div>
      </div>
    </Layout>
  )
}

export default TermsPage
