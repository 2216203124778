import React from 'react'
import { Link } from 'react-router-dom'
import { Layout } from '../../common/components'
import './style.scss'

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <div className="legacyContent">
        <div className="content">
          <h1>Politique de confidentialité</h1>

          <h2></h2>
          <article>
            <p>
              Chez Ama-loc, accessible depuis ama-loc.fr, l'une de nos
              principales priorités est la confidentialité de nos visiteurs. Ce
              document de politique de confidentialité contient des types
              d'informations collectées et enregistrées par ama-loc et
              comment nous les utilisons. Si vous avez des questions
              supplémentaires ou avez besoin de plus d'informations sur notre
              politique de confidentialité, n'hésitez pas à nous contacter.
            </p>
          </article>

          <h2>Log Files</h2>
          <article>
            <p>
              Ama-loc suit une procédure standard d'utilisation des
              fichiers journaux. Ces fichiers enregistrent les visiteurs
              lorsqu'ils visitent des sites Web. Toutes les sociétés
              d'hébergement font cela et font partie de l'analyse des services
              d'hébergement. Les informations collectées par les fichiers
              journaux comprennent les adresses de protocole Internet (IP), le
              type de navigateur, le fournisseur d'accès Internet (FAI), la date
              et l'heure, les pages de renvoi / sortie et éventuellement le
              nombre de clics. Ceux-ci ne sont liés à aucune information
              personnellement identifiable. Le but de ces informations est
              d'analyser les tendances, d'administrer le site, de suivre les
              mouvements des utilisateurs sur le site Web et de collecter des
              informations démographiques.
            </p>
          </article>

          <h2>Cookies et Web Beacons</h2>
          <article>
            <p>
              Comme tout autre site Web, Ama-loc utilise des «cookies». Ces
              cookies sont utilisés pour stocker des informations, y compris les
              préférences des visiteurs et les pages du site Web auxquelles le
              visiteur a accédé ou visité. Les informations sont utilisées pour
              optimiser l'expérience des utilisateurs en personnalisant le
              contenu de notre page Web en fonction du type de navigateur des
              visiteurs et / ou d'autres informations.
            </p>
          </article>

          <h2>Les politiques de confidentialité</h2>
          <article>
            <p>
              Vous pouvez consulter cette liste pour trouver la politique de
              confidentialité de chacun des partenaires publicitaires
              d'Ama-loc. Les serveurs publicitaires ou réseaux
              publicitaires tiers utilisent des technologies telles que les
              cookies, JavaScript ou les balises Web qui sont utilisées dans
              leurs publicités respectives et les liens qui apparaissent sur
              Ama-loc, qui sont envoyés directement au navigateur des
              utilisateurs. Ils reçoivent automatiquement votre adresse IP
              lorsque cela se produit. Ces technologies sont utilisées pour
              mesurer l'efficacité de leurs campagnes publicitaires et / ou pour
              personnaliser le contenu publicitaire que vous voyez sur les sites
              Web que vous visitez. Notez que Ama-loc n'a aucun accès ou
              contrôle sur ces cookies qui sont utilisés par des annonceurs
              tiers.
            </p>
          </article>

          <h2>Politiques de confidentialité des tiers</h2>
          <article>
            <p>
              La politique de confidentialité d'Ama-loc ne s'applique pas
              aux autres annonceurs ou sites Web. Ainsi, nous vous conseillons
              de consulter les politiques de confidentialité respectives de ces
              serveurs publicitaires tiers pour des informations plus
              détaillées. Il peut inclure leurs pratiques et des instructions
              sur la façon de refuser certaines options. Vous pouvez trouver une
              liste complète de ces politiques de confidentialité et de leurs
              liens ici: Liens de politique de confidentialité. Vous pouvez
              choisir de désactiver les cookies via les options de votre
              navigateur individuel. Pour obtenir des informations plus
              détaillées sur la gestion des cookies avec des navigateurs Web
              spécifiques, vous pouvez les trouver sur les sites Web respectifs
              des navigateurs. Que sont les cookies?
            </p>
          </article>

          <h2>Informations sur les enfants</h2>
          <article>
            <p>
              Une autre partie de notre priorité consiste à protéger les enfants
              lorsqu'ils utilisent Internet. Nous encourageons les parents et
              les tuteurs à observer, participer et / ou surveiller et guider
              leur activité en ligne. Ama-loc ne recueille sciemment aucune
              information personnelle identifiable d'enfants de moins de 13 ans.
              Si vous pensez que votre enfant a fourni ce type d'informations
              sur notre site Web, nous vous encourageons vivement à nous
              contacter immédiatement et nous ferons de notre mieux pour
              supprimer rapidement ces informations de nos archives.
            </p>
          </article>

          <h2>Politique de confidentialité en ligne uniquement</h2>
          <article>
            <p>
              Cette politique de confidentialité s'applique uniquement à nos
              activités en ligne et est valable pour les visiteurs de notre site
              Web en ce qui concerne les informations qu'ils ont partagées et /
              ou collectées dans Ama-loc. Cette politique ne s'applique à
              aucune information collectée hors ligne ou via des canaux autres
              que ce site Web.
            </p>
          </article>

          <p>
            En utilisant notre site Web, vous consentez par la présente à notre
            politique de confidentialité et acceptez ses{' '}
            <Link to="/terms" className="link" target="_blank">
              termes et conditions
            </Link>
            .
          </p>
        </div>

        <div className="navigation"></div>
      </div>
    </Layout>
  )
}

export default PrivacyPolicyPage
