import React, { useContext } from 'react'
import classes from 'classnames'
import propTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { UIVersion } from '../../../UIVersionContext'
import Navigation from './Nav'
import './layout.scss'

const Layout = ({ children, noMargin }) => {
  const uiVersion = useContext(UIVersion);

  return (
    <div
      className={classes('wrap', { noMargin, mobile: uiVersion !== 'desktop' })}
    >
      <Navigation />
      <main
        className={classes('main', {
          mobile: uiVersion !== 'desktop',
        })}
      >
        {children}
      </main>
      <footer
        className={classes('footer', {
          mobile: uiVersion !== 'desktop',
        })}
      >
        <div className="top">
          <p>
            SARL <strong>AMA</strong> {new Date().getFullYear()}.
          </p>
          <p>
            <Link to="/legal" className="link" target="_blank">
              Mentions légales
            </Link>
          </p>

          <p>
            <Link to="/terms" className="link" target="_blank">
              Notions générales
            </Link>
          </p>

          <p>
            <Link to="/privacy-policy" className="link" target="_blank">
              Politique de confidentialité
            </Link>
          </p>
        </div>
      </footer>
    </div>
  )
}

Layout.defaultProps = {
  noMargin: false,
}

Layout.propTypes = {
  children: propTypes.node,
  noMargin: propTypes.bool,
}

export default Layout
