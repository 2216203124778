import { registerLocale } from 'react-datepicker'
import React, { useEffect, useCallback, Suspense, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import { LoadingSpinner } from './common/components'
import frenchLocale from 'date-fns/locale/fr'
import { UIVersion } from './UIVersionContext'
import Terms, { CompanyReview, PrivacyPolicy, CompanyTerms } from './pages/Terms'
import NotFoundPage from './pages/NotFound'
import Landing from './pages/Landing'
import CheckoutSuccess from './pages/Checkout/CheckoutSuccess'

registerLocale('fr', frenchLocale)

function determineUIVersion() {
  if (window.innerWidth < 768) {
    return 'mobile'
  } else if (window.innerWidth < 1024) {
    return 'tablet'
  }

  return 'desktop'
}

const App = () => {
  const [version, setUIVersion] = useState(determineUIVersion())

  const switchUIVersionIfNeeded = useCallback(() => {
    const DUIVersion = determineUIVersion()

    if (DUIVersion !== version) {
      setUIVersion(DUIVersion)
    }
  }, [version])

  useEffect(() => {
    window.addEventListener('resize', switchUIVersionIfNeeded)

    return () => {
      window.removeEventListener('resize', switchUIVersionIfNeeded)
    }
  }, [])

  return (
    <UIVersion.Provider value={version}>
      <Switch>
        <Route
          path="/"
          exact
          render={() => (
            <Suspense fallback={<LoadingSpinner />}>
              <Landing />
            </Suspense>
          )}
        />
        <Route path='/checkout' exact component={CheckoutSuccess} />
        <Route path='/reglement' exact component={CompanyTerms} />
        <Route path="/legal" exact component={CompanyReview} />
        <Route path="/terms" exact component={Terms} />
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
        <Route component={NotFoundPage} />
      </Switch>
    </UIVersion.Provider>
  )
}

export default App
