import { all, call, takeEvery } from 'redux-saga/effects'
import apiRequest from '../../../common/fetch'
import appendToFormData from '../../../common/fetch/appendToFormData'
import { FEEDBACKS } from '../../../actions/feedbacks/types'

function* fetchFeedbacksList({ payload: { responseCallback } }) {
  const payloadResponse = yield call(apiRequest, `/feedbacks`, {
    method: 'GET',
  })

  if (payloadResponse.status === 200) {
    const serverResponse = yield payloadResponse.json()

    yield call(responseCallback, serverResponse)
  }
}

function* addFeedback({ payload: { formData, submitCallback } }) {
  const payloadResponse = yield call(apiRequest, '/feedbacks', {
    method: 'POST',
    body: appendToFormData(new FormData(), formData),
  })

  if (payloadResponse.status === 200) {
    const serverResponse = yield payloadResponse.json()

    yield call(submitCallback, serverResponse)
  }
}

export default function* clientsSagaWatcher() {
  yield all([
    takeEvery(FEEDBACKS.ADD, addFeedback),
    takeEvery(FEEDBACKS.GET_LIST, fetchFeedbacksList),
  ])
}
