import React from 'react'
import { Layout } from '../../common/components'
import './style.scss'

const CompanyReview = () => {
  return (
    <Layout>
      <div className="legacyContent">
        <div className="content">
          <h1>Mentions légales</h1>
          <h2 id="notions_generales">INFORMATIONS ÉDITEUR</h2>
          <article>
            <p>
              Le présent site est édité par SARL 'AMA'. Société à
              responsabilité limitée au capital de 20 000 €, enregistrée au RCS
              de Bobigny sous le numéro 752 497 354, dont le siège social est
              sis 2-4 avenue Flore, 95500 Le Thillay; Dont le siège social est
              situé au: 2-4 Avenue FLORE 95500 Le Thillay, Paris, France
            </p>
            <p>
              téléphone: <a href="tel:+33617552052">06 17 55 20 52</a>, <a href='tel:01 34 29 01 36'>01 34 29 01 36</a>
            </p>
            <p>mail: service.amatransport@gmail.com</p>
            <p>SIREN: 752 497 354</p>
            <p>Numero de TVA: FR16752497354</p>
          </article>

          <h2 id="notions_generales">PROPRIÉTÉ INTELLECTUELLE</h2>
          <article>
            Le présent site internet, ainsi que les textes et les images le
            composant, sont protégées au titre du droit d’auteur. L’ensemble de
            ces éléments demeure la proprié.
            <p>
              Aussi, conformément à la réglementation en matière de données
              personnelles, vous disposez d’un droit d’accès, de rectification
              et de suppression des informations qui vous concernent que vous
              pouvez exercer en nous adressant un email à{' '}
              <strong>service.amatransport@gmail.com</strong>.
            </p>
          </article>
        </div>
      </div>
    </Layout>
  )
}

export default CompanyReview
