import React from 'react'
import { withScriptjs } from 'react-google-maps'
import { compose, withProps, lifecycle } from 'recompose'

import StandaloneAutocomplete from './StandaloneAutocomplete'

const PlacesAutocomplete = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyDw3W_5Lj64DhawNVceTKaXgBlALxYPB5A&v=3.exp&libraries=places&language=fr',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
  }),
  lifecycle({
    componentWillUnmount() {
      const restDiv = document.getElementsByClassName('pac-container pac-logo')
      if (restDiv[0]) {
        restDiv[0].parentNode.removeChild(restDiv[0])
      }
    },
    componentDidMount() {
      const refs = {}

      this.setState({
        restrictions: this.props.restrictions,
        placeholder: this.props.placeholder,
        defaultValue: this.props.value || '',
        className: this.props.className || 'autocomplete_input',

        onAutocompleteMounted: ref => {
          refs.autocomplete = ref
        },

        onPlaceChanged: () => {
          const place = refs.autocomplete.getPlace()
          const placedata = {
            location: place.formatted_address,
            location_lat:
              place.geometry && place.geometry.location.lat(lat => lat),
            location_lng:
              place.geometry && place.geometry.location.lng(lng => lng),
          }

          this.props.onChange(placedata)
        },
      })
    },
    componentWillReceiveProps() {
      this.setState({
        defaultValue: this.props.value || '',
      })
    },
  }),
  withScriptjs,
)(props => (
  <StandaloneAutocomplete
    ref={props.onAutocompleteMounted}
    bounds={props.bounds}
    onPlaceChanged={props.onPlaceChanged}
    restrictions={props.restrictions}
  >
    <input
      type="text"
      id="aputocomplete_input"
      onChange={props.onInputChange}
      className={props.className}
      placeholder={props.placeholder}
      defaultValue={props.defaultValue}
    />
  </StandaloneAutocomplete>
))

export default PlacesAutocomplete
