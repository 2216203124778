import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import FacebookLogin from 'react-facebook-login'
import { addFeedback } from '../../../actions/feedbacks'
import { Popup } from '../../../common/components'
import StarsRate from './StarsRate'

const FacebookAuth = ({ setUserDetails }) => {
  return (
    <div className="feedback-modal">
      <h3>
        Pour confirmer votre identité, connectez-vous avec Facebook s'il vous
        plaît
      </h3>
      <FacebookLogin
        appId="870557550455326"
        autoLoad={false}
        fields="name,email,picture"
        callback={setUserDetails}
        textButton="Se connecter avec Facebook"
        disableMobileRedirect={true}
        isMobile={false}
        redirectUri="https://www.ama-loc.fr/"
      />
    </div>
  )
}

const LeaveFeedbackForm = ({
  mobile,
  userDetails,
  onCancelFeedbackSend,
  submitCallback,
}) => {
  const [commentvalue, setCommentvalue] = useState('')
  const [feedbackRate, setFeedbackRate] = useState(4)
  const dispatch = useDispatch()

  const handleCommentValueChange = ({ target: { value } }) => {
    setCommentvalue(value)
  }

  const handleSubmitForm = e => {
    e.preventDefault()

    dispatch(
      addFeedback({
        submitCallback,
        formData: {
          rate: feedbackRate,
          comment: commentvalue,
          user_id: userDetails.id,
          user_name: userDetails.name,
        },
      }),
    )
  }

  const onKeyPress = e => {
    const keyCode = e.keyCode || e.which
    if (keyCode === 13) {
      e.preventDefault()

      return false
    }
  }

  return (
    <div className="feedback-modal">
      <h3>Comment évalueriez-vous notre service, {userDetails.name}?</h3>
      <StarsRate
        editable
        rate={feedbackRate}
        onSetRate={setFeedbackRate}
        mobile={mobile}
      />

      <form
        onKeyPress={onKeyPress}
        onSubmit={handleSubmitForm}
        className="inputForm submit-feedback-form"
      >
        <textarea
          name="email"
          maxLength="250"
          minLength="5"
          value={commentvalue}
          onChange={handleCommentValueChange}
          placeholder="votre comment"
          className="order_form_input container fullWidth"
        />

        <div className="formFooter">
          <button className="button" type="submit">
            Publier le commentaire
          </button>
          <button className="button cancel" onClick={onCancelFeedbackSend}>
            anuler
          </button>
        </div>
        <div className="formFooter">
          <p className="submitOrderNotes">
            En publier cette commentaire, vous acceptez notre système{' '}
            <Link to="terms" className="link" target="_blank">
              conditions d'utilisation.
            </Link>
          </p>
        </div>
      </form>
    </div>
  )
}

const AddFeedbackModal = ({ onSubmitFeedback, mobile }) => {
  const [showModal, setShowModal] = useState(false)
  const [userDetails, setUserDetails] = useState({})

  const toggleShowModal = () => {
    setShowModal(!showModal)
  }

  const submitCallback = data => {
    onSubmitFeedback(data)
    setShowModal(false)
  }

  const clearUserData = () => {
    setUserDetails(null)
  }

  return (
    <>
      <button className="create-feedback-button" onClick={toggleShowModal}>
        Donnez votre avis
      </button>

      {showModal && (
        <Popup onClose={toggleShowModal} mobile={mobile}>
          {userDetails.id ? (
            <LeaveFeedbackForm
              userDetails={userDetails}
              submitCallback={submitCallback}
              onCancelFeedbackSend={clearUserData}
              mobile={mobile}
            />
          ) : (
            <FacebookAuth setUserDetails={setUserDetails} />
          )}
        </Popup>
      )}
    </>
  )
}

export default AddFeedbackModal
