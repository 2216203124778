import { PAYMENTS } from './types'

export const requestBraintreeToken = () => ({
  type: PAYMENTS.REQUEST_BRAINTREE_TOKEN,
})

export const setBraintreeToken = payload => ({
  type: PAYMENTS.SET_BRAINTREE_TOKEN,
  payload,
})

export const submitPayment = payload => ({
  type: PAYMENTS.SUBMIT_PAYMENT,
  payload,
})

export const submitPreorder = payload => ({
  type: PAYMENTS.SUBMIT_PREORDER,
  payload,
})
