import React from 'react'
import { Layout } from '../../common/components'
import './style.scss'

const CompanyTerms = () => {
  return (
    <Layout>
      <div className="legacyContent">
        <div className="content">
          <h1>Règlement pour location de bennes</h1>
          <article>
            Après de réceptionne une <strong>commande de benne</strong>, notre camion arriva à
            l'heure convenu avec vous pour la pose et emplacement-remplacement
            ou enlèvement de benne, pour ça est très importante de reste sur que
            la place réserve est bien disponible et le passage accessible
            jusqu'au moment de manutention de benne bien passe. Le conseil est
            d'avoir un personé responsable présent pour intervenir et réagir sur
            votre terrain, en case de enveniment que peut empêcher et bloquer la
            manutention de benne!
            <p>
              Pour <strong>bien pose la benne</strong> commande été obligatoire d'avoir une place
              réserve et vérifie l'accès en préalable, au de fourni le coordonne
              claire et légitime de placement la benne. Toute la responsabilité
              appartenant au client.
            </p>
            <p>
              Pour l'autorisation de <strong>réserver une place sur voie publique</strong>, 
              Il faudra remplir le formulaire CERFA n°
              14023*01 en avant, et le transmettre à la Mairie de votre commune
              pour prendre une réponse.
            </p>
            <p>
              Pour beaucoup de situation est tré convenable de commander une <strong>benne passante </strong> 
              avec chargement sur place - 45mn ! En case que votre passage est très serré ou bloque,
              pas de place resserve, ou vous préférez de charger la benne sur
              place direct - commandes une benne passante qu'arrive sur votre
              adresse et vous la chargez en 45 minutes - âpre que la benne est
              installée. Attentions la benne passante peu pas bloquer la
              circulation, ferme une voie et rester plus de 45 minutes.
            </p>
            <p>
              Toute l'échange de benne sont commande obligatoire en préalable.
            </p>
            <p>
              <strong>Le passage sur chantier</strong> sans aucunes opérations sont prisse en charge
              par clients. Dan le cas que vous avez bien passé une commande chez
              nous et le camion arrivant dan le moment convenu, la benne est
              bloquée et non accessible, ou la commande est annulé pendant que
              le camion est sur la route ver adresse fourni - nous ne pouvons
              pas prendre aucune responsabilité et comme ça vous êtes facture
              avec un montant 150 euro HT pour <strong>le passage</strong> en vide.
            </p>
            <p>
              Toute le <strong>surcharge de benne</strong> que posse en danger la transportations et
              circulations sur la route sont facture en supplémentaires avec 35€
              HT/m3
            </p>
          </article>
        </div>
      </div>
    </Layout>
  )
}

export default CompanyTerms
