import React from 'react'
import { Link } from 'react-router-dom'
import OrderForm from './components/OrderForm'
import FeedbackSection from './components/FeedbackSection'
import benneImg from '../../style/assets/IMG_4893.jpg'
import benneImg2 from '../../style/assets/IMG_48963.jpg'
import fbLogo from '../../style/assets/fb-logo.png'
import igLogo from '../../style/assets/ig-logo.png'
import computerImg from '../../style/assets/computer.png'
import recycleImg from '../../style/assets/recycle.png'
import garbageImg from '../../style/assets/garbage.png'
import skipImg from '../../style/assets/skip.png'
import partner1Logo from '../../style/assets/partner1.png'
import partner2Logo from '../../style/assets/partner2.png'
import partner3Logo from '../../style/assets/partner3.png'
import partner4Logo from '../../style/assets/partner4.png'
import connectLine from '../../style/assets/connect-line.png'
import formImg from '../../style/assets/order_img.png'
import galleryimg1 from './assets/gallery/IMG_4854.jpg'
import galleryimg2 from './assets/gallery/IMG_4900.jpg'
import galleryimg3 from './assets/gallery/IMG_4891.jpg'
import galleryimg4 from './assets/gallery/IMG_4901.jpg'
import galleryimg5 from './assets/gallery/IMG_4904.jpg'
import galleryimg6 from './assets/gallery/IMG_4872.jpg'
import logoImage from './assets/logo.svg'

import {
  calendarIcon,
  callIcon,
  chatIcon,
  coinIcon,
  markIcon,
  watchIcon,
  arrowDownIcon,
} from './assets'
import './style.scss'

const focusFormRef = () => {
  document.getElementById('aputocomplete_input').focus()
}

const LandingPage = () => {
  return (
    <div className="landing-page">
      <section className="intro-poster">
        <div className="section-wrap">
          <header>
            <div className="pull-left">
              <img src={logoImage} alt="logo" className="logo" />
              <a className="contact-number" href="tel:+33 6 17 55 20 52">
                <img className="icon" src={callIcon} alt="" />
                06 17 55 20 52
              </a>
              <a className="contact-number" href="tel:01 34 29 01 36">
                <img className="icon" src={callIcon} alt="" />
                01 34 29 01 36
              </a>
            </div>
            <div className="pull-right">
              <a
                className="contact-number"
                href="https://www.facebook.com/amalocparis"
                target="_blank"
              >
                <img className="icon logo" src={fbLogo} alt="fb-logo" />
              </a>
              <a
                className="contact-number"
                href="https://www.instagram.com/ama.loc.paris"
                target="_blank"
              >
                <img className="icon logo" src={igLogo} alt="ig-logo" />
              </a>
            </div>
          </header>
          <div className="poster-details">
            <div className="description">
              <h1>Location des bennes en l'Ile-de-France</h1>
              <p>
                Met à votre disposition location de bennes de 8, 10 et 15 mètres
                cubes avec chauffeur pour récupérer et transporter vos déchets
                industriels banals pour les clients particuliers et
                professionnels.
              </p>

              <button className="order-button" onClick={focusFormRef}>
                Passer commande
              </button>
            </div>
          </div>
          <a className="scroll-down" href="#prix-list">
            voir les prix <img className="icon" src={arrowDownIcon} alt="" />
          </a>
        </div>
      </section>
      <section className="partners-list">
        <h1>En partenariat avec</h1>
        <div className="section-wrap">
          <div className="partner-card">
            <img src={partner1Logo} alt="suez logo" />
          </div>
          <div className="partner-card">
            <img src={partner2Logo} alt="ect collecte logo" />
          </div>
          <div className="partner-card">
            <img src={partner3Logo} alt="ille de france logo" />
          </div>
          <div className="partner-card">
            <img src={partner4Logo} alt="veolia logo" />
          </div>
        </div>
      </section>
      <section className="features-details primary">
        <div className="section-wrap">
          <div className="features-list">
            <div className="feature-item">
              <div className="icon-wrap">
                <img className="icon" src={calendarIcon} alt="" />
              </div>
              <p>Max 2 semaines de location</p>
              <span>
                Pour toute location supérieur à 2 semaines, un supplément
                100€/mois sera facturé.
              </span>
            </div>
            <div className="feature-item">
              <div className="icon-wrap">
                <img className="icon" src={coinIcon} alt="" />
              </div>
              <p>Un prix raisonnable</p>
              <span>
                Notre équipe commerciale définit avec vous les details de
                location et vous propose un tarif à l'unité adapté.
              </span>
            </div>
            <div className="feature-item">
              <div className="icon-wrap">
                <img className="icon" src={watchIcon} alt="" />
              </div>
              <p>Service rapide et de qualité</p>
              <span>
                Après réservation votre benne est déposée dans max les 24h.
              </span>
            </div>
          </div>
          <h3 id="prix-list">Les prix</h3>
          <span>Les prix correspondent aux types de déchets et au volume</span>

          <div className="prices-list">
            <div className="price-item heading">
              <div className="value label"></div>
              <div className="value">8m³</div>
              <div className="value">10m³</div>
              <div className="value">15m³</div>
              <div className="value">30m³</div>
            </div>
            <div className="price-item">
              <div className="value label">Gravats</div>
              <div className="value">340 EUR, HT</div>
              <div className="value">390 EUR, HT</div>
              <div className="value">-</div>
              <div className="value">-</div>
            </div>
            <div className="price-item">
              <div className="promotion-label">offre spéciale, 8% off</div>
              <div className="value label">Mélanges</div>
              <div className="value">
                <p>410 EUR, HT</p>
                <p className="old-price">440 EUR, HT</p>
              </div>
              <div className="value">
                <p>455 EUR, HT</p>
                <p className="old-price">490 EUR, HT</p>
              </div>
              <div className="value">
                <p>615 EUR, HT</p>
                <p className="old-price">660 EUR, HT</p>
              </div>
              <div className="value">
                <p>1070 EUR, HT</p>
                <p className="old-price">1150 EUR, HT</p>
              </div>
            </div>
            <div className="price-item">
              <div className="promotion-label">offre spéciale, 8% off</div>
              <div className="value label">Bois</div>
              <div className="value">
                <p>400 EUR, HT</p>
                <p className="old-price">430 EUR, HT</p>
              </div>
              <div className="value">
                <p>445 EUR, HT</p>
                <p className="old-price">480 EUR, HT</p>
              </div>
              <div className="value">
                <p>585 EUR, HT</p>
                <p className="old-price">630 EUR, HT</p>
              </div>
              <div className="value">
                <p>975 EUR, HT</p>
                <p className="old-price">1050 EUR, HT</p>
              </div>
            </div>
            <div className="price-item">
              <div className="value label">Végétaux</div>
              <div className="value">430 EUR, HT</div>
              <div className="value">480 EUR, HT</div>
              <div className="value">630 EUR, HT</div>
              <div className="value">1050 EUR, HT</div>
            </div>
            <span style={{ textAlign: 'left' }}>
              *en fonction de la quantite de bennes fourni, le prix peut etre
              negocie
            </span>
          </div>
        </div>
      </section>
      <section className="delivery-steps">
        <h3>Comment cela fonctionne?</h3>
        <p>Notre conseil peut convenir pour toutes les situations</p>
        <br />
        <br />
        <div className="section-wrap">
          <div className="step-card">
            <div className="image">
              <img src={computerImg} alt="" />
            </div>
            <span>étape 1</span>
            <h4>commandez</h4>
            <p>
              Commandez en ligne la benne adapatée a votre volume de déchets
            </p>
          </div>
          <img src={connectLine} alt="" className="connect" />
          <div className="step-card">
            <div className="image">
              <img src={garbageImg} alt="" />
            </div>
            <span>étape 2</span>
            <h4>placez</h4>
            <p>La benne arrive chez vous. Prenez le temps de la remplir</p>
          </div>
          <img src={connectLine} alt="" className="connect" />
          <div className="step-card">
            <div className="image">
              <img src={skipImg} alt="" />
            </div>
            <span>étape 3</span>
            <h4>recupere</h4>
            <p>La benne est recupere à la date souhaitée</p>
          </div>
          <img src={connectLine} alt="" className="connect" />
          <div className="step-card">
            <div className="image">
              <img src={recycleImg} alt="" />
            </div>
            <span>étape 4</span>
            <h4>recyclez</h4>
            <p>Vos déchets collectés sont triés et recyclés</p>
          </div>
        </div>
      </section>
      <section className="devis-generator primary" id="devis">
        <img src={formImg} alt="" />
        <OrderForm />
      </section>
      <section className="photos-gallery primary" id="gallery">
        <div className="gallery">
          <figure class="gallery__item gallery__item--1">
            <img src={galleryimg1} alt="" />
          </figure>
          <figure class="gallery__item gallery__item--2">
            <img src={galleryimg2} alt="" />
          </figure>
          <figure class="gallery__item gallery__item--3">
            <img src={galleryimg3} alt="" />
          </figure>
          <figure class="gallery__item gallery__item--4">
            <img src={galleryimg4} alt="" />
          </figure>
          <figure class="gallery__item gallery__item--5">
            <img src={galleryimg5} alt="" />
          </figure>
          <figure class="gallery__item gallery__item--6">
            <img src={galleryimg6} alt="" />
          </figure>
        </div>
      </section>
      <section className="services-details">
        <div className="section-wrap">
          <article>
            <img src={benneImg} alt="" />
            <div className="details">
              <p>
                Notre service depuis 2010 réussit à trier jusqu'à 4000m³ de
                déchets par ân dans notre centre unique de triage situé au Val
                de Oise, puis transporté a notre centre partenaire spécialisé
                dans le recyclage, un volume impressionnant de déchets traite
                pour le bénéfice en Ile de France. Le secteur d'activité est
                dédié aux clients professionnels et particuliers qui ont besoin
                de notre intervention pour collecter et enlever des déchets
                banals ou industriels au mélange, de gravats, terre,
                encombrants, sauf les déchets dangereux comme le plomb, amiante
                et chimique.
              </p>
            </div>
          </article>
          <article>
            <div className="details">
              <p>
                A la demande nous pouvons délivrer un suivi de vos déchets pour
                chaque commande après 24 heures! Pensez à la préservation de
                l'environnement, en demandent notre service, vous participez à
                la revalorisation de déchets et recyclage des matéries
                premiéres! Un circuit favorable pour la planète, un service
                écoresponsable!
              </p>
            </div>
            <img src={benneImg2} alt="" className="right-aligned" />
          </article>
        </div>
      </section>
      <section className="feedbacks-list primary">
        <div className="section-wrap">
          <h3>Avis de service</h3>
          <FeedbackSection />
        </div>
      </section>
      <section className="contacts-list primary" id="contact">
        <div className="section-wrap">
          <div className="contact-card">
            <div className="icon-wrap">
              <img className="icon" src={chatIcon} alt="" />
            </div>
            <h4>Comment louer une benne?</h4>
            <p>Notre conseil est bon convenir pour toutes les situations</p>
            <Link to="/reglement">
              voir le règlement pour la manutention des bennes
            </Link>
          </div>
          <div className="contact-card">
            <div className="icon-wrap">
              <img className="icon" src={markIcon} alt="" />
            </div>
            <h4>Emplacement</h4>
            <p>
              Vous pouvez nous rencontrer à notre bureau et discuter en détail
              de vos questions.
            </p>
            <a>2-4 Avenue FLORE 95500 Le Thillay, Paris, France</a>
          </div>
          <div className="contact-card">
            <div className="icon-wrap">
              <img className="icon" src={callIcon} alt="" />
            </div>
            <h4>Contactez-nous</h4>
            <p>
              Appelez-nous si votre question nécessite une réponse immédiate.
              Service disponible pendant les jours de travail (de lundi jusqu'à
              samedi) entre 8h et 18h. Or envoyez-nous une question par l'email.
            </p>
            <a href="tel:+33 6 17 55 20 52">06 17 55 20 52</a>
            <br />
            <a href="tel:01 34 29 01 36">01 34 29 01 36</a>
            <br />
            <a>service.amatransport@gmail.com</a>
          </div>
        </div>
      </section>
      <footer>
        <div className="section-wrap">
          <span>Copyright © SARL AMA 2012 - {new Date().getFullYear()}</span>
          <span>
            <Link to="/legal" className="link" target="_blank">
              Mentions légales
            </Link>

            <Link to="/terms" className="link" target="_blank">
              Notions générales
            </Link>
          </span>
        </div>
      </footer>
    </div>
  )
}

export default LandingPage
