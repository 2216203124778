import { all, takeEvery, call } from 'redux-saga/effects'
import { PAYMENTS } from '../../../actions/payments/types'
import appendToFormData from '../../../common/fetch/appendToFormData'
import apiRequest from '../../../common/fetch'

function* submitPreorder({ payload: { formData, submitCallback } }) {
  const payloadResponse = yield call(apiRequest, '/payments/preorder', {
    method: 'POST',
    body: appendToFormData(new FormData(), formData),
  })

  submitCallback(payloadResponse)
}

export default function* eventsSagaWatcher() {
  yield all([
    takeEvery(PAYMENTS.SUBMIT_PREORDER, submitPreorder),
  ])
}
