import React from 'react'
import { starEmptyIcon, starFillIcon } from '../assets'

const StarsRate = ({ editable, rate = 1, onSetRate, mobile }) => {
  return (
    <div className={`stars-rate${mobile ? '-mobile' : ''}`}>
      <img
        alt=''
        className="icon"
        src={starFillIcon}
        onClick={() => {
          if (editable) onSetRate(1)
        }}
      />
      <img
        alt=''
        className="icon"
        src={rate > 1 ? starFillIcon : starEmptyIcon}
        onClick={() => {
          if (editable) onSetRate(2)
        }}
      />
      <img
        alt=''
        className="icon"
        src={rate > 2 ? starFillIcon : starEmptyIcon}
        onClick={() => {
          if (editable) onSetRate(3)
        }}
      />
      <img
        alt=''
        className="icon"
        src={rate > 3 ? starFillIcon : starEmptyIcon}
        onClick={() => {
          if (editable) onSetRate(4)
        }}
      />
      <img
        alt=''
        className="icon"
        src={rate > 4 ? starFillIcon : starEmptyIcon}
        onClick={() => {
          if (editable) onSetRate(5)
        }}
      />
    </div>
  )
}

export default StarsRate
