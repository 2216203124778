import { equals } from 'ramda'
import { useState } from 'react'

const useForm = (initialState = {}) => {
  const [values, setValues] = useState(initialState)

  const handleChange = event => {
    event.persist()

    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value,
    }))
  }

  const customHandleChange = (key, value) => {
    setValues(values => ({
      ...values,
      [key]: value,
    }))
  }

  const onResetState = formRef => {
    if (formRef) {
      formRef.reset()
      setValues(initialState)

      if (Object.keys(values).find(equals('location'))) {
        const autocompleteInput = document.getElementById('aputocomplete_input')

        if (autocompleteInput) {
          autocompleteInput.value = ''
        }
      }
    }
  }

  return {
    customHandleChange,
    handleChange,
    handleResetForm: onResetState,
    values,
  }
}

export default useForm
