import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import StarsRate from './StarsRate'
import { useDispatch } from 'react-redux'
import { getFeedbacksList } from '../../../actions/feedbacks'
import AddFeedbackModal from './AddFeedbackModal'
import '../masonry.css'

const FeedbackSection = ({ mobile }) => {
  const dispatch = useDispatch()
  const [feedbacksList, setFeedbacksList] = useState([])

  useEffect(() => {
    dispatch(
      getFeedbacksList(({ list }) => {
        setFeedbacksList(list)
      }),
    )
  }, [])

  const onSubmitFeedback = data => {
    setFeedbacksList([...feedbacksList, data])
  }

  return (
    <div className="section-content">
      <div className="section-heading">
        <div className="tabs">{/* <span>Avis de service</span> */}</div>
        <AddFeedbackModal onSubmitFeedback={onSubmitFeedback} mobile={mobile} />
      </div>
      <div className="section-body masonry">
        {feedbacksList.map(feedback => (
          <div className="testimonial masonry-item" key={feedback.id}>
            <div className="masonry-content">
              <div className="heading">
                <div className="autor">
                  <h4>{feedback.user_name}</h4>
                </div>
                <StarsRate rate={feedback.rate} mobile={mobile} />
              </div>
              <div className="created-date">
                {format(feedback.created_at * 1000, 'MMMM d, yyyy HH:mm')}
              </div>
              <p>{feedback.comment}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FeedbackSection
