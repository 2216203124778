import React, { memo } from 'react'
import { NavLink } from 'react-router-dom'
import propTypes from 'prop-types'
import logoImage from "./logo.svg";

const Nav = () => (
  <header className="header">
    <nav>
      <div className="pullLeft">
        <NavLink to="/" className="navLink logo">
          <img src={logoImage} alt="logo"  />
        </NavLink>
      </div>
      <div className="pullRight" />
    </nav>
  </header>
)

Nav.propTypes = {
  isAuthenticated: propTypes.bool.isRequired,
  handleLogoutUser: propTypes.func.isRequired,
  authUser: propTypes.instanceOf(Object),
}

export default memo(Nav)
