
import {StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
    },
    header: {
      fontSize: 17,
      margin: 10,
      display: 'flex',
      textAlign: 'center',
      flexDirection: 'column',
      marginBottom: 30
    },
    logo: {
      height: 40,
      width: 90,
      display: 'flex'
    },
    table: {
      fontSize: 15,
      margin: 10,
      display: 'flex',
      flexDirection: 'column'
    },
    tableRow: {
      padding: 10,
      display: 'flex',
      flexDirection: 'row',
      borderBottom: '1px solid',
      justifyContent: 'space-between'
    },
    footer: {
      fontSize: 12,
      margin: 10,
      marginTop: 50,
      display: 'flex',
      flexDirection: 'column',
    },
  });

  export default styles