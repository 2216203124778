import React from 'react'
import { Page, Text, View, Document, Image, usePDF } from '@react-pdf/renderer';
import { Layout } from '../../common/components'
import logo from '../../style/assets/ama_logo.png'
import styles from './invoiceStyles'

const decipher = (salt) => {
  const textToChars = (text) => text.split('').map(c => c.charCodeAt(0));
  const applySaltToChar = (code) => textToChars(salt).reduce((a,b) => a ^ b, code);

  return (encoded) => encoded.match(/.{1,2}/g)
    ?.map(hex => parseInt(hex, 16))
    .map(applySaltToChar)
    .map(charCode => String.fromCharCode(charCode))
    .join('');
}

const checkoutDecipher = decipher(process.env.REACT_APP_SECRET)

const CheckoutSuccess = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const decodedData = checkoutDecipher(urlParams.get('InvoiceData'))
  const { amount, date, invoice_number, reference } = JSON.parse(decodedData)

  const Invoice = (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image src={logo} style={styles.logo} />
          <Text>Justificatif de reglement</Text>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text>Service offert</Text>
            <Text>Location de benne</Text>
          </View>
          <View style={styles.tableRow}>
            <Text>Date de reglement</Text>
            <Text>{date}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text>Montant</Text>
            <Text>{amount} EUR</Text>
          </View>
          <View style={styles.tableRow}>
            <Text>Canal de reglement</Text>
            <Text>Site internet de telepayement</Text>
          </View>
          <View style={styles.tableRow}>
            <Text>Moyen de reglement</Text>
            <Text>Carte bancaire</Text>
          </View>
          <View style={styles.tableRow}>
            <Text>Numero de commande</Text>
            <Text>{invoice_number}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text>Numéro de transaction</Text>
            <Text>{reference}</Text>
          </View>
        </View>
        <View style={styles.footer}>
          <Text>Pour plus de renseignements sur ce justificatif et vos demarches, contactez nous:</Text>
          <Text>website: www.ama-loc.fr</Text>
          <Text>téléphone: 06 17 55 20 52; 01 34 29 01 36</Text>
          <Text>mail: service.amatransport@gmail.com</Text>
        </View>
      </Page>
    </Document>
  )

  const [instance] = usePDF({ document: Invoice });

  return (
    <Layout>
      {
        urlParams.get('Code') === '00000000' ? (
          <h2>votre transaction a été effectuée avec succès</h2>
        ) : (
          <>
            <h2>sorry, votre transaction a échoué</h2>
          </>
        )
      }

      {
        urlParams.get('Code') === '00000000' && amount && date && invoice_number && (
          <a
            className='link'
            target='_blank'
            href={instance.url}
            fileName={`facture-${invoice_number}.pdf`}
          >
            {instance.loading ? 'chargement...' : 'recevoir la facture'}
          </a>
        )
      }
    </Layout>
  )
}

export default CheckoutSuccess
