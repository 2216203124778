import { FEEDBACKS } from './types'

export const getFeedbacksList = responseCallback => ({
  type: FEEDBACKS.GET_LIST,
  payload: { responseCallback },
})

export const addFeedback = payload => ({
  type: FEEDBACKS.ADD,
  payload,
})
